// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".overflow_hXnXo{-webkit-overflow-scrolling:touch;display:flex;flex-direction:column;max-height:4.5rem;overflow:hidden;overflow-y:auto;scroll-behavior:smooth;scrollbar-color:var(--color-black-300) var(--color-white);scrollbar-width:thin}.overflow_hXnXo::-webkit-scrollbar{height:1rem}.overflow_hXnXo::-webkit-scrollbar-track{background:var(--color-white)}.overflow_hXnXo::-webkit-scrollbar-thumb{background-color:var(--color-black-300);border:.25rem solid var(--color-white);border-radius:calc(1rem - var(--border-radius-medium))}.overflow_hXnXo::-webkit-scrollbar-thumb:hover{border-width:.2rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overflow": "overflow_hXnXo"
};
module.exports = ___CSS_LOADER_EXPORT___;
